
export function base64ToHex(base64: string): string {
    // Handle web-safe base64 by replacing URL-safe chars
    const sanitized = base64.replace(/-/g, '+').replace(/_/g, '/');
    const binary = atob(sanitized);
    return Array.from(binary)
        .map(char => char.charCodeAt(0).toString(16).padStart(2, '0'))
        .join('');
}

export function hexToBase64(hex: string): string {
    // Remove 0x prefix if present
    hex = hex.replace('0x', '');

    // Pad with leading 0 if odd length
    if (hex.length % 2 !== 0) {
        hex = '0' + hex;
    }

    // Convert each pair of hex digits to a character
    const binary = hex.match(/.{1,2}/g)?.map(byte =>
        String.fromCharCode(parseInt(byte, 16))
    ).join('') || '';

    return btoa(binary);
}